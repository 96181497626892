import React from 'react';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Layout, RandomOp, Typography } from '../../components';
import {
  MainTitle, MainPart, Figure, List,
} from '../../components/opComponents';

const ArthroseAcromioClaviculaire = () => (
  <Layout
    title="Arthroscopie du poignet : Présentation de la technique"
    description="L'arthroscopie du poignet est une technique chirurgicale récente, développée à la fin des années 80. Découvrez et comprenez cette technique."
    headerStyle="white"
  >
    <div className="flex flex-col px-4 py-16 mx-auto space-y-4 max-w-screen-mdx">
      <Link to="/chirurgie-de-la-main" className="flex flex-row items-center space-x-3 uppercase text-primary group">
        <FontAwesomeIcon className="transition-all duration-300 ease-in-out group-hover:text-link" icon="long-arrow-alt-left" />
        <Typography className="group-hover:text-link" weight="semiBold" transition="medium">Retour aux opérations de la main</Typography>
      </Link>
      <div className="flex flex-col space-y-6">
        <MainTitle currentOpTitle="Arthroscopie du poignet" />
        <MainPart title="Qu'est-ce qu'une arthroscopie ?">
          <Typography weight="bold">
            L&apos;arthroscopie du poignet
            {' '}
            <Typography variant="span">
              est une technique chirurgicale récente, développée à la fin des années 80.
              Le principe est d&apos;introduire une caméra et des instruments miniaturisés
              afin de diagnostiquer et de traiter les lésions du poignet. Il s&apos;agit
              d&apos;un traitement peu invasif, avec des suites post-opératoires le plus
              souvent simples (moins de douleurs, récupération plus rapide)
              et des cicatrices cutanées minimes.
            </Typography>
          </Typography>
        </MainPart>
        <MainPart title="Quel matériel est utilisé lors de l'arthroscopie du poignet ?">
          <Typography weight="bold">
            Traction du membre :
            {' '}
            <Typography variant="span">
              réalisée à l&apos;aide d&apos;une tour de traction. Elle est verticale et permet
              la décoaptation du poignet à l&apos;aide de doigtiers japonais, facilitant
              ainsi l&apos;introduction de l&apos;optique et des instruments (Figures 1 et 2)
            </Typography>
          </Typography>
          <div className="flex flex-col items-start space-x-0 space-y-8 mdx:flex-row mdx:space-x-8 mdx:space-y-0">
            <Figure
              maxWidth="max-w-[200px]"
              imageName="figure1-arthroscopiedupoignet.jpg"
              caption="Figure 1. Tour de traction utilisée en arthroscopie du poignet"
              currentOpTitle="Arthroscopie du poignet"
            />
            <Figure
              maxWidth="max-w-[250px]"
              imageName="figure2-arthroscopiedupoignet.jpg"
              caption="Figure 2. Traction du poignet à l&apos;aide de doigtiers japonais permettant l&apos;introduction de l&apos;optique et des instruments"
              currentOpTitle="Arthroscopie du poignet"
            />
          </div>
          <Typography weight="bold">
            Irrigation du poignet :
            {' '}
            <Typography variant="span">
              faite au sérum physiologique lors de l&apos;intervention
            </Typography>
          </Typography>
          <Typography weight="bold">
            Optique : de petite taille
            {' '}
            <Typography variant="span">
              (diamètre 1,9 ou 2,7mm) pour une introduction facile dans le poignet (Figure 3).
            </Typography>
          </Typography>
          <Figure
            maxWidth="max-w-[400px]"
            imageName="figure3-arthroscopiedupoignet.jpg"
            caption="Figure 3. Optique miniaturisé pour une introduction facile dans le poignet"
            currentOpTitle="Arthroscopie du poignet"
          />
          <Typography weight="bold">
            Colonne d&apos;arthroscopie :
            {' '}
            <Typography variant="span">
              est un matériel commun à toute intervention sous vidéochirurgie.
              Elle comprend un moniteur, une caméra et une source lumineuse (Figure 4).
            </Typography>
          </Typography>
          <Figure
            maxWidth="max-w-[250px]"
            imageName="figure4-arthroscopiedupoignet.jpg"
            caption="Figure 4. Colonne d&apos;arthroscopie permettant la visualisation de l&apos;intervention et le branchement des instruments"
            currentOpTitle="Arthroscopie du poignet"
          />
          <Typography weight="bold">
            Instruments miniaturisés :
            {' '}
            <Typography variant="span">
              crochet palpeur, pinces, sonde de vaporisation,
              fraise et résecteur motorisés, ancres...
            </Typography>
          </Typography>
        </MainPart>
        <MainPart title="Comment se déroule l'intervention chirurgicale pour une arthroscopie du poignet ?">
          <Typography>
            Elle est, dans la majorité des cas, réalisée sous anesthésie
            locorégionale (le bras est uniquement endormi) et en ambulatoire
            (pas d&apos;hospitalisation).
          </Typography>
          <Typography>
            2 à 4 petites incisions cutanées horizontales réalisées
            dans les plis du poignet permettent,
            en général, l&apos;exploration et le traitement de nombreuses pathologies
            du poignet (Figure 5).
            Les incisions cutanées sont, le plus souvent, de petites tailles et ne nécessitent pas
            de suture cutanée.
          </Typography>
          <Figure
            maxWidth="max-w-[400px]"
            imageName="figure5-arthroscopiedupoignet.jpg"
            caption="Figure 5. Arthroscopie du poignet en ambulatoire"
            currentOpTitle="Arthroscopie du poignet"
          />
          <Typography>
            Les pathologies fréquemment traitées sous arthroscopie
            sont (cf. chapitres spécifiques) :
          </Typography>
          <List>
            <List.Item>
              <Typography weight="bold">
                Atteintes ligamentaires du poignet
                {' '}
                <Typography variant="span">
                  (rupture ou distension) : en fonction du type de lésion et de son étendue,
                  une réinsertion ligamentaire par ancre ou retente ligamentaire par la
                  sonde de vaporisation (« shrinkage thermique ») est réalisée.
                </Typography>
              </Typography>
            </List.Item>
            <List.Item>
              <Typography weight="bold">
                Fracture du radius :
                {' '}
                <Typography variant="span">
                  l&apos;arthroscopie trouve tout son intérêt en cas de fracture intéressant
                  la surface articulaire du radius. Elle permet une réduction le plus
                  souvent anatomique du cartilage articulaire, minimisant ainsi la
                  survenue d&apos;arthrose post-traumatique.
                </Typography>
              </Typography>
            </List.Item>
            <List.Item>
              <Typography weight="bold">
                Fracture du scaphoïde :
                {' '}
                <Typography variant="span">
                  un contrôle arthroscopique peut être utile lors du vissage ou brochage
                  du scaphoïde, surtout en cas de fracture déplacée.
                </Typography>
              </Typography>
            </List.Item>
            <List.Item>
              <Typography weight="bold">
                Pseudarthrose du scaphoïde :
                {' '}
                <Typography variant="span">
                  en cas de non consolidation du scaphoïde (pseudarthrose), l&apos;avivement
                  et la greffe du foyer de pseudarthrose peuvent être faits sous
                  arthroscopie. Cette technique a pour avantage de ne pas dévasculariser
                  le scaphoïde et de minimiser le risque de raideur post-opératoire.
                </Typography>
              </Typography>
            </List.Item>
            <List.Item>
              <Typography weight="bold">
                Ligament triangulaire
                {' '}
                <Typography variant="span">
                  (TFCC) : une réinsertion ou un débridement de ce ligament sont
                  réalisés en fonction du siège et de l&apos;étendue de la rupture.
                </Typography>
              </Typography>
            </List.Item>
            <List.Item>
              <Typography weight="bold">
                Kyste de poignet :
                {' '}
                <Typography variant="span">
                  une exérèse du kyste par l&apos;intérieur du poignet permet de
                  minimiser la rançon cicatricielle et la raideur post-opératoire.
                </Typography>
              </Typography>
            </List.Item>
            <List.Item>
              <Typography weight="bold">
                Résection de la partie distale de l&apos;ulna ou « Wafer Procedure » :
                {' '}
                <Typography variant="span">
                  est réalisée à la fraise motorisée en cas du syndrome de l&apos;ulna long,
                  responsable d&apos;un conflit ulno-carpien (entre l&apos;extrémité distale
                  de l&apos;ulna et les os du poignet).
                </Typography>
              </Typography>
            </List.Item>
          </List>
        </MainPart>
        <MainPart title="Quels sont les risques de complications après une intervention chirurgicale pour une arthroscopie du poignet ?">
          <Typography>
            Il n&apos;existe pas d&apos;intervention chirurgicale sans risque de
            complications secondaires. Les complications peuvent être classées en deux catégories :
          </Typography>
          <Typography size="mdx" weight="bold">Complications non spécifiques :</Typography>
          <List>
            <List.Item>
              <Typography>
                Infection du site opératoire pouvant nécessiter une
                intervention de nettoyage associée à la prise d&apos;antibiotiques
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                Hématome pouvant nécessiter une évacuation en cas
                de menace cutanée ou compression nerveuse
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                Algodystrophie. Son apparition est indépendante du type de chirurgie.
                Elle évolue en deux phases : phase chaude (main gonflée, douloureuse
                avec transpiration) puis froide (prédominance de la raideur). L&apos;évolution
                est le plus souvent longue (12-18 mois) et des séquelles sont
                possibles (douleur et/ou raideur des articulations des
                doigts et/ou poignet et/ou épaule)
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                Accidents liés à l&apos;anesthésie
              </Typography>
            </List.Item>
          </List>
          <Typography size="mdx" weight="bold">Complications spécifiques :</Typography>
          <List>
            <List.Item>
              <Typography>
                Hernie synoviale ou kyste en regard des cicatrices : ces
                kystes disparaissent en général au bout de quelques semaines
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                Atteinte des tendons extenseurs et des petits rameaux nerveux
                de la main. Elle survient lors de la réalisation des voies d&apos;abord
                ou lors de la chirurgie et peut nécessiter une réparation chirurgicale.
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                Persistance des douleurs et de la gêne
                fonctionnelle en cas de pathologie intra-articulaire
              </Typography>
            </List.Item>
          </List>
        </MainPart>
        <MainPart title="Pourquoi choisir une opération chirurgicale par arthroscopie du poignet ?">
          <Typography weight="bold">
            L&apos;arthroscopie du poignet
            {' '}
            <Typography variant="span">
              permet de diagnostiquer et de traiter des lésions jusqu&apos;alors inaccessibles
              et de repousser certaines techniques conventionnelles à ciel ouvert, plus
              délabrantes. Il s&apos;agit d&apos;une chirurgie délicate,
              peu invasive avec des suites
              post-opératoires plus simples, qui devrait être réalisée par un chirurgien
              du membre supérieur formé à l&apos;arthroscopie du poignet.
            </Typography>
          </Typography>
        </MainPart>
      </div>
    </div>
    <RandomOp type="mains" />
  </Layout>
);

export default ArthroseAcromioClaviculaire;
